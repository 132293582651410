










































import Vue from "vue";
import { CognitoState } from "@/store/modules/cognito";

export default Vue.extend({
  name: "App",
  data: () => ({
    activeTab: "/",
    tabs: [{ key: "setting", name: "全体設定", route: "/setting/information" }],
  }),
  computed: {
    authenticated: function () {
      return CognitoState.authenticated;
    },
  },
});
