import Vue from "vue";
import VueRouter from "vue-router";
import { CognitoState } from "@/store/modules/cognito";

Vue.use(VueRouter);

const signout = async (to: any, from: any, next: any) => {
  CognitoState.signout();
  next("/signin");
};

const routes = [
  {
    path: "/",
    name: "Top",
    redirect: { name: "InformationSetting" },
    meta: { requiredAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import("@/components/pages/Signin.vue"),
  },
  {
    path: "/setting",
    component: () => import("@/components/pages/setting/Index.vue"),
    redirect: { name: "InformationSetting" },
    children: [
      {
        path: "information",
        name: "InformationSetting",
        component: () => import("@/components/pages/setting/Information.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "message_card_template",
        name: "MessageCardTemplateSetting",
        component: () =>
          import("@/components/pages/setting/MessageCardTemplate.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: () => import("@/components//pages/setting/Calendar.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "pickup_store",
        name: "PickupStoreSetting",
        component: () => import("@/components//pages/setting/PickupStore.vue"),
        meta: { requiredAuth: true },
      },
    ],
  },
  {
    path: "/signout",
    beforeEnter: signout,
  },
  {
    path: "*",
    component: () => import("@/components/pages/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    CognitoState.checkAuthentication().then(() => {
      if (CognitoState.authenticated) {
        //ログイン済み
        next();
      } else {
        //未ログイン
        next({
          path: "/signin",
          query: { redirect: to.fullPath },
        });
      }
    });
  } else {
    next();
  }
});

export default router;
